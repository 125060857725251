import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { UserHealthDataState } from './user-health-data.state';

const featureSelector = createFeatureSelector<UserHealthDataState>(
  'userHealthData' as keyof AppState
);

const idsSelector = createSelector(featureSelector, (state) => state.ids);

const entitiesSelector = createSelector(
  featureSelector,
  (state) => state.entities
);

// Pagination
const hasNextSelector = createSelector(
  featureSelector,
  (state) => !!state.hasNext
);

const pageNumberSelector = createSelector(
  featureSelector,
  (state) => state.pageNumber
);

const loadingSelector = createSelector(
  featureSelector,
  (state) => state.loading
);

/**
 * Returns health activity data with the earliest record date or undefined
 */
const earliestDateSelector = createSelector(entitiesSelector, (entities) => {
  return Object.values(entities).reduce(
    (acc, entity) =>
      !acc || entity?.recordDate < acc.recordDate ? entity : acc,
    undefined
  );
});

// **Note** this is different in its ordered
const entitiesArrSelector = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) =>
    (ids as string[])
      .map((id) => entities[id])
      .sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }

        if (a.createdAt > b.createdAt) {
          return 1;
        }

        return 0;
      })
);

export const userHealthDataSelectors = {
  // Core
  idsSelector,
  entitiesSelector,
  entitiesArrSelector,
  // Pagination
  hasNextSelector,
  pageNumberSelector,
  // Loading
  loadingSelector,
  earliestDateSelector
};
