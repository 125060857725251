export const DistanceConversions = {
  /**
   * 0.762 - An avg step length is 2.5 ft or 0.762 meters per step
   */
  STEPS_TO_METERS: 0.762,
  /**
   * 1000 - Meters per kilometer
   */
  METERS_PER_KILOMETER: 1000,
  /**
   * 1609.34 - Meters per mile
   */
  METERS_PER_MILE: 1609.34
};
