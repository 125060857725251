import {
  AdminListUserRegistrationsParams,
  AdminUser,
  CommonResponse,
  ProviderHealthUploadFilter,
  ProviderHealthUserRegistration,
  SelfReportedUserRegData,
  UserRegistration,
  UserRegistrationId,
  UserRequisition,
  OnsiteUserRegistration,
  Company
} from '@common';
import {
  entityHttpActionFactory,
  entitySelectionActionFactory
} from '@ehs-ngrx/common';
import { createAction, props } from '@ngrx/store';

export const userRegistrationNgrxActions = {
  clearAll: createAction('[USER_REGISTRATION_NGRX] CLEAR_ALL'),
  set: createAction(
    '[USER_REGISTRATION_NGRX] SET',
    props<{
      entity: UserRegistration;
    }>()
  ),

  get: entityHttpActionFactory<
    {
      id: string;
    },
    {
      entity: UserRegistration;
    }
  >('[USER_REGISTRATION_NGRX] GET'),

  updateOptionalTests: entityHttpActionFactory<
    {
      id: string;
      optionalTests: string[];
    },
    UserRegistration,
    CommonResponse
  >('[USER_REGISTRATION_NGRX] UPDATE_OPTIONAL_TESTS'),

  updateAdditionalTests: entityHttpActionFactory<
    {
      id: string;
      additionalTests: string[];
    },
    UserRegistration,
    CommonResponse
  >('[USER_REGISTRATION_NGRX] UPDATE_ADDITIONAL_TESTS'),

  updateServiceTypes: entityHttpActionFactory<
    {
      userRegistrationId: string;
      serviceTypes: string[];
    },
    UserRegistration,
    CommonResponse
  >('[USER_REGISTRATION_NGRX] UPDATE_SERVICE_TYPES'),

  updateRegistrationDetails: entityHttpActionFactory<
    {
      userRegistration: Partial<UserRegistration>;
    },
    UserRegistration,
    CommonResponse
  >('[USER_REGISTRATION_NGRX] UPDATE_REGISTRATION_DETAILS'),

  // This is used for the Provider Health Uploads table
  listByFilter: entityHttpActionFactory<
    ProviderHealthUploadFilter,
    {
      healthProviderUploads: ProviderHealthUserRegistration[];
      hasNext: boolean;
    },
    { error: CommonResponse }
  >('[USER_REGISTRATION_NGRX] LIST_BY_FILTER'),

  // This is used for client admin user table
  listByCompany: entityHttpActionFactory<
    { company: Company | string },
    {
      userRegistrations: OnsiteUserRegistration[];
    },
    { error: CommonResponse }
  >('[USER_REGISTRATION_NGRX] LIST_BY_COMPANY'),

  // This is used for the Provider Health Uploads table
  loadMore: entityHttpActionFactory<
    ProviderHealthUploadFilter,
    {
      healthProviderUploads: ProviderHealthUserRegistration[];
      hasNext: boolean;
    },
    { error: CommonResponse }
  >('[USER_REGISTRATION_NGRX] LOAD_MORE'),

  // This is used for the Provider Health Uploads table
  setPageNumber: createAction(
    '[USER_REGISTRATION_NGRX] SET_PAGE_NUMBER',
    props<{ pageNumber: number }>()
  ),

  // This is used for the Provider Health Uploads table
  clear: createAction('[USER_REGISTRATION_NGRX] CLEAR'),

  listAsAdmin: entityHttpActionFactory<
    AdminListUserRegistrationsParams,
    {
      entities: UserRegistration[];
    }
  >('[USER_REGISTRATION_NGRX] LIST_AS_ADMIN'),
  ...entitySelectionActionFactory<UserRegistrationId, 'USER_REGISTRATION_NGRX'>(
    'USER_REGISTRATION_NGRX'
  ),

  getUserReq: entityHttpActionFactory<
    {
      userRegistration: UserRegistrationId;
    },
    {
      userRegistration: UserRegistrationId;
      userRequisition: UserRequisition;
    },
    CommonResponse
  >('[USER_REGISTRATION_NGRX] GET_USER_REQ'),

  getUserReqs: entityHttpActionFactory<
    {
      userRegistrations: UserRegistrationId[];
    },
    {
      userRequisitions: UserRequisition[];
    },
    CommonResponse
  >('[USER_REGISTRATION_NGRX] GET_USER_REQS'),

  remove: entityHttpActionFactory<
    {
      id: UserRegistrationId;
    },
    {
      id: UserRegistrationId;
    }
  >('[USER_REGISTRATION_NGRX] REMOVE'),

  removeVaccinationAndRegistration: entityHttpActionFactory<
    {
      id: UserRegistrationId;
    },
    {
      id: UserRegistrationId;
    }
  >('[USER_REGISTRATION_NGRX] REMOVE_VACCINATION_AND_REGISTRATION'),

  removeRegistrationOnly: entityHttpActionFactory<
    {
      id: UserRegistrationId;
    },
    {
      id: UserRegistrationId;
    }
  >('[USER_REGISTRATION_NGRX] REMOVE_REG_ONLY'),

  release: entityHttpActionFactory<
    {
      ids: UserRegistrationId[];
    },
    {
      entities: UserRegistration[];
    }
  >('[USER_REGISTRATION_NGRX] RELEASE'),

  sendToLab: entityHttpActionFactory<
    {
      ids?: UserRegistrationId[];
      eventLocation?: string;
      admin?: AdminUser;
      resend?: boolean;
    },
    {
      entities: UserRegistration[];
    }
  >('[USER_REGISTRATION_NGRX] SEND_TO_LAB'),

  exportToCsv: entityHttpActionFactory<
    {
      company: string;
      eventLocation?: string;
    },
    { payload: Record<string, never> }
  >('[USER_REGISTRATION_NGRX] EXPORT_TO_CSV'),

  markNoShow: entityHttpActionFactory<
    {
      userRegistrations: OnsiteUserRegistration[];
    },
    {
      entities: UserRegistration[];
    }
  >('[USER_REGISTRATION_NGRX] MARK_NO_SHOW'),

  removeVaccination: entityHttpActionFactory<{
    userRegistrations: OnsiteUserRegistration[];
  }>('[USER_REGISTRATION_NGRX] REMOVE_VACCINATION'),

  markCompleted: entityHttpActionFactory<
    {
      userRegistrations: OnsiteUserRegistration[];
    },
    {
      entities: UserRegistration[];
    }
  >('[USER_REGISTRATION_NGRX] MARK_COMPLETED'),

  update: entityHttpActionFactory<
    {
      entity: SelfReportedUserRegData;
    },
    {
      entity: UserRegistration;
    }
  >('[USER_REGISTRATION_NGRX] UPDATE'),

  updateNotes: entityHttpActionFactory<
    {
      entity: ProviderHealthUserRegistration;
    },
    {
      entity: UserRegistration;
    }
  >('[USER_REGISTRATION_NGRX] UPDATE_NOTES'),

  reschedule: entityHttpActionFactory<
    {
      entity: Partial<UserRegistration>;
    },
    {
      entity: UserRegistration;
    }
  >('[USER_REGISTRATION_NGRX] RESCHEDULE'),

  createWalkinRegistration: entityHttpActionFactory<
    {
      entity: Partial<UserRegistration>;
    },
    {
      entity: UserRegistration;
    }
  >('[USER_REGISTRATION_NGRX] CREATE_WALKIN'),

  createAdditionalWalkinRegistration: entityHttpActionFactory<
    {
      entity: Partial<UserRegistration>;
    },
    {
      entity: UserRegistration;
    }
  >('[USER_REGISTRATION_NGRX] CREATE_WALKIN'),
  changeOnsiteEventDate: entityHttpActionFactory<
    {
      entity: Partial<OnsiteUserRegistration>;
    },
    {
      entity: UserRegistration;
    }
  >('[USER_REGISTRATION_NGRX] UPDATE_EVENT')
};
