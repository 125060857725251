import { LocationData } from '../location-data';
import { HealthProgram, HealthProgramType } from './health-program';

export enum WalkingProgramType {
  STEPS = 'steps',
  DESTINATION = 'destination'
}

/**
 * A health program that tracks walking activity.
 * The goal can be a simple number of steps or the distance in
 * steps from the starting location to the destination location.
 */
export interface WalkingProgram extends HealthProgram {
  /**
   * The method of determining the goal of the program.
   */
  walkingProgramType: WalkingProgramType;
  /**
   * For a steps program, the number of steps required.
   */
  requiredSteps?: number;
  /**
   * For a destination program, the location to reach.
   */
  destinationLocation?: LocationData;
  /**
   * For a destination program, the location to start from.
   */
  startingLocation?: LocationData;

  /**
   * For a destination program, the additional stops to reach.
   * This is optional and can be used to create a multi-stop
   * walking program.
   */
  additionalStops?: AdditionalLocationData[];
}

/**
 * Used by walking programs to store additional location data for potential stops
 * in between the starting and destination locations.
 */
export interface AdditionalLocationData extends LocationData {
  /**
   * The title of the location.
   */
  title?: string;
  /**
   * Whether to include this location in the map.
   */
  showLocation?: boolean;
  /**
   * Index of the location in the list of stops.
   */
  index?: number;
}

export const isWalkingProgram = (
  healthProgram: HealthProgram
): healthProgram is WalkingProgram =>
  healthProgram?.type === HealthProgramType.WALKING;
