import { DateTime, Duration, DurationLikeObject } from 'luxon';
import { DbDocument } from '../models/db-document';
import { TimeUtil } from './time-util';

export class DateFormatError extends Error {}

/**
 * Utility class to create and convert "date-strings".
 * This class uses Luxon for parsing and conversions.
 */
export class DateUtil {
  /**
   * Returns the most recently updated document in the array.
   * If there is a tie, we select the first one found.
   */
  public static getMostRecentlyUpdated(params: {
    docs: Array<Pick<DbDocument, 'updatedAt'>>;
  }): Pick<DbDocument, 'updatedAt'> | undefined {
    const { docs } = params;

    if (!docs || !docs.length) {
      return undefined;
    }

    return (docs || []).reduce((latest, doc) =>
      doc.updatedAt > latest.updatedAt ? doc : latest
    );
  }

  /**
   * Returns if the date string is over the age of 17 from today
   * Internally will check for a valid date.
   *
   * **note** this should only be used on formatted date strings: 2020-03-12
   */
  public static isOver17(birthDay: string): boolean {
    if (!DateTime.fromISO(birthDay).isValid || !this.isValidDate(birthDay)) {
      return false;
    }

    return this.getAge(birthDay) >= 17;
  }

  /**
   * Returns if the date string is past the year 1899
   *
   * Works on dates formatted as: YYYY-MM-DD or MM/DD/YYYY
   */
  public static isPast1900(date: string): boolean {
    try {
      // Get the YYYY-MM-DD date string format
      const dateStr = DateUtil.convertStringDate(date);

      if (!dateStr || !this.isValidDate(dateStr)) {
        return false;
      }

      return this.getYear(dateStr) >= 1900;
    } catch (err) {
      return false;
    }
  }

  /**
   * Returns if the given date string is a valid date string.
   *
   * **note** this is more strict that what luxon can take using just
   * DateTime.fromISO(date) but that is by design.
   */
  public static isValidDate(date: string): boolean {
    if (!DateTime.fromISO(date).isValid) {
      return false;
    }
    const [year, month, day] = date.split('-').map(Number);

    if (
      !DateTime.fromObject({
        year,
        month,
        day
      }).isValid
    ) {
      return false;
    }

    return true;
  }

  /**
   * Returns the year from the date string
   */
  public static getYear(date: string): number {
    if (!this.isValidDate(date)) {
      throw new DateFormatError('Invalid Date string provided' + date);
    }
    const [year] = date.split('-').map(Number);

    return year;
  }

  /**
   * Returns the month from the date string
   */
  public static getMonth(date: string): number {
    if (!this.isValidDate(date)) {
      throw new DateFormatError('Invalid Date string provided' + date);
    }
    const [, month] = date.split('-').map(Number);

    return month;
  }

  /**
   * Returns the day of the month from date string
   */
  public static getDay(date: string): number {
    if (!this.isValidDate(date)) {
      throw new DateFormatError('Invalid Date string provided' + date);
    }
    const [, , day] = date.split('-').map(Number);

    return day;
  }

  /**
   * Converts a date to a "date-string". We throw an error
   * if the calculated date is not valid. We can also take in an ISO string
   */
  public static convertToString(date: Date | string, setzone?: string): string {
    const pad = (str: string | number) =>
      (('' + str) as string).padStart(2, '0');
    const dateTime =
      typeof date === 'string'
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date as Date);

    if (!dateTime.isValid) {
      throw new DateFormatError('Converted DateTime not valid ' + date);
    }

    const { year, month, day } = setzone ? dateTime.setZone(setzone) : dateTime;

    return `${year}-${pad(month)}-${pad(day)}`;
  }

  /**
   * Convert string dates from MM/dd/yyyy to yyyy-MM-dd
   * If format doesn't match throw an error
   * Useful to migrate from different ways to generate "date strings"
   * to the yyyy-MM-dd format most of the app uses.
   */
  public static convertStringDate(date: string): string {
    // If date match format already return it.
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return date;
    }

    // Check if date is string and of format MM/dd/yyyy, convert to yyyy-MM-dd
    if (date.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      return DateTime.fromFormat(date, 'MM/dd/yyyy').toFormat('yyyy-MM-dd');
    }
    throw new DateFormatError('Converted DateTime not valid ' + date);
  }

  /**
   * Returns if the given string is a life-point date either with or without seconds
   * which is the following:
   * EX: 201601121637 (no seconds) translated to: 2016-01-12 16:37
   * or
   * EX: 20200616133914 (with seconds) translates to 2020-06-16 13:39 14
   */
  private static isLifePointDate(date: string): boolean {
    return date && new RegExp(/^(\d{12}|\d{14})$/).test(date);
  }

  /**
   * Converts a life-point date string
   */
  private static convertLifePointDateFromString(date: string): Date {
    const year = Number(date.slice(0, 4));
    const month = Number(date.slice(4, 6));
    const day = Number(date.slice(6, 8));
    const hour = Number(date.slice(8, 10));
    const minute = Number(date.slice(10, 12));
    const second = Number(date.slice(12, 14));
    const dateTime = DateTime.fromObject({
      year,
      month,
      day,
      hour,
      minute,
      second
    });

    if (!dateTime.isValid) {
      throw new DateFormatError('Converted DateTime not valid ' + date);
    }

    return dateTime.toUTC().toJSDate();
  }

  /**
   * Returns the JS date from the date
   * and time strings.
   */
  public static convertDateTime(params: {
    /**
     * Date string
     */
    date: string;
    /**
     * 24 hour string
     */
    time: string;
  }): Date | undefined {
    const { date, time } = params;

    if (!date || !time) {
      return undefined;
    }
    const [year, month, day] = date.split('-').map(Number);
    const dateTime = DateTime.fromObject({
      year,
      month,
      day,
      hour: TimeUtil.getHour(time),
      minute: TimeUtil.getMinutes(time)
    });

    if (!dateTime.isValid) {
      throw new DateFormatError('Converted DateTime not valid ' + date);
    }

    return dateTime.toUTC().toJSDate();
  }

  /**
   * Converts a date to a JS date. This should only be used
   * for situations where we MUST have a JS Date.
   * This method also handles life-point date strings
   */
  public static convertFromString(date: string): Date | undefined {
    if (this.isLifePointDate(date)) {
      return this.convertLifePointDateFromString(date);
    }

    if (!date) {
      return undefined;
    }
    const [year, month, day] = date.split('-').map(Number);
    const dateTime = DateTime.fromObject({
      year,
      month,
      day
    });

    if (!dateTime.isValid) {
      throw new DateFormatError('Converted DateTime not valid ' + date);
    }

    return dateTime.toUTC().toJSDate();
  }

  /**
   * Utility function that returns a future date from the starting date, or today
   * if the startingDate is not given
   */
  public static getFutureDate(
    params: DurationLikeObject & { startingDate?: Date; zone?: string }
  ): Date {
    const { startingDate, zone, ...luxonParams } = params;

    return DateTime.fromJSDate(startingDate || new Date(), { zone })
      .plus(Duration.fromObject(luxonParams))
      .toJSDate();
  }

  public static getPastDate(
    params: DurationLikeObject & { startingDate?: Date; zone?: string }
  ) {
    const { startingDate, zone, ...luxonParams } = params;

    return DateTime.fromJSDate(startingDate || new Date(), { zone })
      .minus(Duration.fromObject(luxonParams))
      .toJSDate();
  }

  private static now() {
    return new Date();
  }

  public static getNowCSTDate(): Date {
    return DateTime.fromJSDate(new Date(), {
      zone: 'UTC-6'
    }).toJSDate();
  }

  /**
   *
   * Returns the age in years from right now to the given date string,
   * will throw an error if the date given is not valid.
   */
  public static getAge(date: string): number {
    if (!this.isValidDate(date)) {
      throw new DateFormatError('Invalid Date string provided' + date);
    }
    const [year, month, day] = date.split('-').map(Number);
    const age = Math.floor(
      DateTime.fromJSDate(this.now()).diff(
        DateTime.fromObject({ year, month, day }),
        'years'
      ).years
    );

    // Prevent any odd ages in the future
    if (age <= 0) {
      return 0;
    }

    return age;
  }

  /**
   * Returns the date string for today/right now
   */
  public static getToday(): string {
    return this.convertToString(new Date());
  }

  /**
   * Date format utility function that returns a "full-date" format, similar
   * to Angular's 'fulldate' setting:
   * See: https://angular.io/api/common/DatePipe#pre-defined-format-options
   *
   * General format from Angular docs:
   * 'EEEE, MMMM d, y' (Monday, June 15, 2015).
   *
   * Uses Luxon's DATE_HUGE format:
   * https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#static-get-DATE_HUGE
   * @param date the date string, will throw an error if given an invalid date string
   */
  public static getFullDate(date: string): string {
    if (!this.isValidDate(date)) {
      throw new DateFormatError('Invalid Date string provided' + date);
    }
    const [year, month, day] = date.split('-').map(Number);

    return DateTime.fromObject({ year, month, day }).toLocaleString(
      DateTime.DATE_HUGE
    );
  }

  /**
   * Converts a date to a "date-string". with the format dd/mm/yyyy We throw an error
   * if the calculated date is not valid
   */
  public static convertToShortString(date: Date): string {
    const dateTime =
      typeof date === 'string'
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date);

    if (!dateTime.isValid) {
      throw new DateFormatError('Converted DateTime not valid ' + date);
    }

    const pad = (str: string | number) =>
      (('' + str) as string).padStart(2, '0');
    const { year, month, day } = dateTime;

    return `${pad(month)}/${pad(day)}/${year}`;
  }

  /**
   * Serial date is the days calculation from December 30th 1899.
   * Ref: https://developers.google.com/sheets/api/reference/rest/v4/DateTimeRenderOption#ENUM_VALUES.SERIAL_NUMBER
   *
   * **note** I'm not sure about using this, when we could use Luxon instead.
   */
  public static convertToSerialDate(date: Date): number {
    if (!date) {
      return null;
    }
    // Hours*minutes*seconds*milliseconds
    const dayMs = 24 * 60 * 60 * 1000;

    return Math.round(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (date.setHours(0, 0, 0, 0) - (new Date(1899, 11, 30) as any)) / dayMs
    );
  }

  /**
   * Converts the JS date to Luxon DateTime. This function also makes
   * sure to strip any time.
   */
  public static getDateTime(date: string | Date): DateTime {
    let inputDate: DateTime;

    if (typeof date === 'string') {
      inputDate = DateTime.fromFormat(date, 'yyyy-MM-dd').toUTC();
    } else {
      inputDate = DateTime.fromJSDate(date).toUTC();
    }

    return DateTime.local(
      inputDate.year,
      inputDate.month,
      inputDate.day,
      0,
      0,
      0
    );
  }

  /**
   * Return the ISO String that includes the Date and Time
   * together with the timezone
   */
  public static getDateAndTime(params: {
    date: string;
    time: string;
    timezone: string;
  }): string {
    const { date, time, timezone } = params;

    if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      throw new DateFormatError('Date format should be yyyy-MM-dd');
    }

    return DateTime.fromObject(
      {
        year: +date.substr(0, 4),
        month: +date.substr(5, 2),
        day: +date.substr(8, 2),
        hour: TimeUtil.getHour(time),
        minute: TimeUtil.getMinutes(time)
      },
      {
        zone: timezone
      }
    ).toISO({ suppressMilliseconds: true });
  }

  /**
   * Return the Date to a given format. If no date is passed, then we use the
   * new Date(). If no timezone is passed, we use Chicago timezone.
   */
  public static getDateFormatted(params: {
    format: string;
    date?: Date;
    timezone?: string;
  }): string {
    const { format, date, timezone } = params;
    const dateTime = DateTime.fromJSDate(date || new Date(), {
      zone: timezone || 'America/Chicago'
    });

    return dateTime.toFormat(format);
  }

  /**
   * Format user birthday value to MM/dd/yyyy
   * Supported user inputs include:
   * - yyyy-MM-dd
   * - MM/dd/yyyy | M/d/yyyy | MM/d/yyyy | M/dd/yyyy
   * - MM-dd-yyyy | M-d-yyyy | MM-d-yyyy | M-dd-yyyy
   * - MM.dd.yyyy | M.d.yyyy | MM.d.yyyy | M.dd.yyyy
   * - MMddyyyy
   * - Mdyyyy
   * If format doesn't match return value
   *
   * TODO: rename to be more generic, can be used for
   * format basically any date to our format.
   */
  public static formatDate(value: string) {
    if (!value || !value.length) {
      return undefined;
    }

    const pad = (str: string | number) =>
      (('' + str) as string).padStart(2, '0');

    // Check if date is of format yyyy-MM-dd (example 1990-06-06)
    if (value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return DateTime.fromISO(value).toFormat('MM/dd/yyyy');
    }

    // Check if date is of format MM/dd/yyyy (example 06/06/1990)
    if (value.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
      const splitDate = value.split('/');

      return DateTime.fromFormat(
        `${pad(splitDate[0])}/${pad(splitDate[1])}/${splitDate[2]}`,
        'MM/dd/yyyy'
      ).toFormat('MM/dd/yyyy');
    }

    // Check if date is of format MM-dd-yyyy (example 06-06-1990)
    if (value.match(/^\d{1,2}-\d{1,2}-\d{4}$/)) {
      const splitDate = value.split('-');

      return DateTime.fromFormat(
        `${pad(splitDate[0])}/${pad(splitDate[1])}/${splitDate[2]}`,
        'MM/dd/yyyy'
      ).toFormat('MM/dd/yyyy');
    }

    // Check if date is of format MM.dd.yyyy (example 06.06.1990)
    if (value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/)) {
      const splitDate = value.split('.');

      return DateTime.fromFormat(
        `${pad(splitDate[0])}/${pad(splitDate[1])}/${splitDate[2]}`,
        'MM/dd/yyyy'
      ).toFormat('MM/dd/yyyy');
    }

    // Check if date is of format Mdyyyy (example 661990)
    if (value.match(/^\d{6}$/)) {
      return DateTime.fromFormat(
        `${pad(value.substr(0, 1))}/${pad(value.substr(1, 1))}/${value.substr(
          2,
          4
        )}`,
        'MM/dd/yyyy'
      ).toFormat('MM/dd/yyyy');
    }

    // Check if date is of format MMddyyyy (example 06061990)
    if (value.match(/^\d{8}$/)) {
      return DateTime.fromFormat(
        `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 4)}`,
        'MM/dd/yyyy'
      ).toFormat('MM/dd/yyyy');
    }

    return value;
  }

  /**
   * Will convert time for a specific time zone.
   * Must pass the following:
   * 1. Date
   * 2. Time zone you want time to convert to ie. ('American/Chicago')
   */
  public static convertTimeZone(date, timeZoneString) {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: timeZoneString }
      )
    );
  }

  /**
   * Returns a the given number of dates up to and including the end date.
   */
  public static getDatesInRange(params: {
    date: string;
    range: number;
    direction: 'next' | 'previous';
  }): string[] {
    const { date, range, direction } = params;

    if (!date || !range) {
      return [];
    }

    if (!this.isValidDate(date)) {
      return [];
    }

    if (range < 1) {
      return [];
    }

    const dates = [];

    if (direction === 'previous') {
      let lastDate = date;

      for (let i = 0; i < range; i++) {
        dates.push(lastDate);

        const lastDateDate = this.convertFromString(lastDate);

        lastDateDate.setDate(lastDateDate.getDate() - 1);

        lastDate = this.convertToString(lastDateDate);
      }

      return dates.reverse();
    }

    if (direction === 'next') {
      let startDate = date;

      for (let i = 0; i < range; i++) {
        dates.push(startDate);

        const nextDateDate = this.convertFromString(startDate);

        nextDateDate.setDate(nextDateDate.getDate() + 1);

        startDate = this.convertToString(nextDateDate);
      }

      return dates;
    }

    return [];
  }
}
