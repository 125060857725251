import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { AppState } from './app-state';
import { AuthEffects } from './auth/auth.effects';
import { AuthReducer } from './auth/auth.state';
import { CoachingSessionEffects } from './coaching-session/coaching-session.effects';
import { CoachingSessionReducer } from './coaching-session/coaching-session.state';
import { CompanyEffects } from './company/company.effects';
import { CompanyReducer } from './company/company.state';
import { ErrorReportEffects } from './error-report/error-report.effects';
import { EventLocationEffects } from './event-location/event-location.effects';
import { EventLocationReducer } from './event-location/event-location.state';
import { EventServiceEffect } from './event-service/event-service.effect';
import { EventServiceReducer } from './event-service/event-service.state';
import { HraResultEffects } from './hra-result/hra-result.effects';
import { HraResultReducer } from './hra-result/hra-result.state';
import { logoutMetaReducer } from './meta-reducers/logout-meta-reducer';
import { UserRegistrationEffects } from './user-registration/user-registration.effects';
import { UserRegistrationReducer } from './user-registration/user-registration.state';
import { UserResultEffects } from './user-result/user-result.effects';
import { UserResultReducer } from './user-result/user-result.state';
import { UserTestEffects } from './user-test/user-test.effects';
import { UserTestReducer } from './user-test/user-test.state';
import { NotificationEffects } from './notifications/notification.effects';
import { NotificationReducer } from './notifications/notification.state';
import { UserVaccinationEffects } from './user-vaccination/user-vaccination.effects';
import { UserVaccinationReducer } from './user-vaccination/user-vaccination.state';
import { HealthProgramReducer } from './health-program/health-program.state';
import { HealthProgramEffects } from './health-program/health-program.effects';
import { HealthProgramTeamReducer } from './health-program-teams/health-program-team.state';
import { HealthProgramTeamEffects } from './health-program-teams/health-program-team.effects';
import { UserHealthDataEffects } from './user-health-data/user-health-data.effects';
import { UserHealthDataReducer } from './user-health-data/user-health-data.state';

@NgModule({
  imports: [
    StoreModule.forRoot<AppState>(
      {
        auth: AuthReducer,
        company: CompanyReducer,
        eventLocation: EventLocationReducer,
        userRegistration: UserRegistrationReducer,
        eventService: EventServiceReducer,
        userTest: UserTestReducer,
        hraResult: HraResultReducer,
        healthProgram: HealthProgramReducer,
        healthProgramTeam: HealthProgramTeamReducer,
        coaching: CoachingSessionReducer,
        userResult: UserResultReducer,
        router: routerReducer,
        notification: NotificationReducer,
        userVaccination: UserVaccinationReducer,
        userHealthData: UserHealthDataReducer
      },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true
        },
        metaReducers: [logoutMetaReducer]
      }
    ),
    EffectsModule.forRoot([
      AuthEffects,
      CompanyEffects,
      ErrorReportEffects,
      EventLocationEffects,
      UserRegistrationEffects,
      EventServiceEffect,
      UserTestEffects,
      HraResultEffects,
      HealthProgramEffects,
      HealthProgramTeamEffects,
      CoachingSessionEffects,
      UserResultEffects,
      NotificationEffects,
      UserVaccinationEffects,
      UserHealthDataEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true
    })
  ]
})
export class AppStoreModule {}
