import { UserTest } from '@common';
import { entityHttpActionFactory } from '@ehs-ngrx/common';
import { createAction, props } from '@ngrx/store';

export const userTestNgrxActions = {
  setLoading: createAction(
    '[USER_TEST_NGRX] SET_LOADING',
    props<{
      loading: boolean;
    }>()
  ),
  setMany: createAction(
    '[USER_TEST_NGRX] SET_MANY',
    props<{
      entities: UserTest[];
    }>()
  ),

  list: entityHttpActionFactory<undefined, { entities: UserTest[] }>(
    '[USER_TEST_NGRX] LIST'
  )
};
