import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommonResponse,
  EventService,
  EventServiceIncentivesStats,
  EventServiceStats,
  ServiceType
} from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminEventServiceHttpService {
  private baseUrl = 'api/v1/admin';
  private clientAdminUrl = 'api/v1/client';

  constructor(private http: HttpClient) {}

  /**
   * Returns the event-services for the given company
   * @param companyId the company to get the event-services for
   */
  public listEventServices(
    companyId: string,
    params?: { clientAdmin: boolean }
  ): Observable<EventService[]> {
    return this.http.get<EventService[]>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/service/list`,
      {
        params: new HttpParams().append('company', companyId)
      }
    );
  }

  /**
   * Gets a single event-service, for any company
   */
  public getAny(
    id: string,
    params?: { clientAdmin: boolean }
  ): Observable<EventService> {
    return this.http.get<EventService>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/service/${id}`
    );
  }

  /**
   *
   * Gets a list of event services for a company, each containing a nested offsite event service
   */

  public getEventServiceStats(
    eventServiceId: string,
    params?: { clientAdmin: boolean }
  ): Observable<EventServiceStats> {
    return this.http.get<EventServiceStats>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/service/${eventServiceId}/stats`
    );
  }

  /**
   * Get Event service incentives stats
   * @param eventServiceId
   * @returns EventServiceIncentivesStats
   */
  public getIncentiveStats(eventServiceId: string) {
    return this.http.get<EventServiceIncentivesStats>(
      `${this.baseUrl}/service/${eventServiceId}/incentives/stats`
    );
  }

  /**
   * Releases all the user-registrations for the given type
   * and given user-registrations.
   */
  public releaseRegistrationsToLab(params: {
    eventService: string;
    serviceType: ServiceType;
  }): Observable<CommonResponse> {
    const { eventService, serviceType } = params;

    return this.http.post<CommonResponse>(
      `api/v1/admin/service/${eventService}/release`,
      {
        // This is given twice for clarity
        id: eventService,
        serviceType
      }
    );
  }

  /**
   * Export Missing results
   */
  public exportMissingResults(params: {
    eventServiceId: string;
    eventLocationId: string;
  }): Observable<CommonResponse> {
    const { eventServiceId, eventLocationId } = params;

    return this.http.get<CommonResponse>(
      `api/v1/admin/service/missing-export/${eventServiceId}/${eventLocationId}`
    );
  }
}
