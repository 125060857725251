import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AdminUserScopes,
  AdminUserSearchForm,
  ClientAdminScopes,
  getId,
  UserRegistrationId,
  OnsiteRegistrationType,
  PartialUser,
  UniqueUser,
  User,
  UserWithRegistrations,
  isUserWithRegistrations,
  UserFilter,
  UserUtil
} from '@common';
import { EntityFacade } from '@ehs-ngrx/common';
import { UserRegistrationNgrxFacade } from '@ehs-ngrx/user-registration-ngrx';
import { ClientAdminUserActionType, UserActionType } from '@ehs-ui';
import { Actions, ofType } from '@ngrx/effects';
import { createSelector, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { userNgrxActions } from './user-ngrx.actions';
import {
  userNgrxFeatureSelector,
  userNgrxSelectors
} from './user-ngrx.selectors';
import { ParentUserNgrxState, UserNgrxState } from './user-ngrx.state';

@Injectable({
  providedIn: 'root'
})
export class UserNgrxFacade extends EntityFacade<
  User | UserWithRegistrations,
  string,
  ParentUserNgrxState,
  UserNgrxState
> {
  // These 3 are used for user-management
  public hasNext$ = this.store.select(userNgrxSelectors.hasNextSelector);
  public pageNumber$ = this.store.select(userNgrxSelectors.pageNumberSelector);
  public actionLoading$ = this.store.select(
    userNgrxSelectors.actionLoadingSelector
  );

  /**
   * Returns the list of user-ids that are selected
   */
  public selected$ = this.store.select(userNgrxSelectors.selectedSelector);
  /**
   * Returns a list of users that are selected
   */
  public selectedUsers$ = this.store.select(
    userNgrxSelectors.selectedEntitiesArrSelector
  );

  /**
   * Returns all sorted users by their first and last names.
   */
  public sortedUsers$ = this.entitiesArray$.pipe(
    map((users) =>
      (users || []).slice().sort((a, b) => {
        const aName = `${a.firstName || ''} ${a.lastName || ''}`;
        const bName = `${b.firstName || ''} ${b.lastName || ''}`;

        if (aName < bName) {
          return -1;
        }

        if (aName > bName) {
          return 1;
        }

        return 0;
      })
    )
  );

  /**
   * Action that is emitted on a user-success action.
   */
  public onCreatePartialUserSuccess$ = this.actions$.pipe(
    ofType(userNgrxActions.createPartialUser.success)
  );

  constructor(
    store: Store<ParentUserNgrxState>,
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private userRegistrationFacade: UserRegistrationNgrxFacade
  ) {
    super(store, userNgrxFeatureSelector);
  }

  public clearUsers() {
    this.store.dispatch(userNgrxActions.clearUsers());
  }

  public listUsers(params: AdminUserSearchForm) {
    this.store.dispatch(userNgrxActions.list.req(params));
  }

  public loadMore(payload: AdminUserSearchForm) {
    this.store.dispatch(userNgrxActions.loadMore({ payload }));
  }

  public setPageNumber(pageNumber: number) {
    this.store.dispatch(userNgrxActions.setPageNumber({ pageNumber }));
  }

  public loginAsUser(userId: string, url?: string) {
    this.store.dispatch(userNgrxActions.loginAsUser.req({ userId, url }));
  }

  public resetPassword(user: User) {
    this.store.dispatch(userNgrxActions.resetPassword.req({ user }));
  }

  public removeMFA(user: User) {
    this.store.dispatch(userNgrxActions.removeMFA.req({ user }));
  }

  public disableMFA(user: User) {
    this.store.dispatch(userNgrxActions.disableMFA.req({ user }));
  }

  public enableMFA(user: User) {
    this.store.dispatch(userNgrxActions.enableMFA.req({ user }));
  }

  public verifyEmail(user: User) {
    this.store.dispatch(userNgrxActions.verifyEmail.req({ user }));
  }

  public unlockAccount(user: User) {
    this.store.dispatch(userNgrxActions.unlockAccount.req({ user }));
  }

  public moveUserCompany(user: User) {
    this.store.dispatch(userNgrxActions.moveUserCompany.req({ user }));
  }

  public removeUser(user: User) {
    this.store.dispatch(userNgrxActions.removeUser.req({ user }));
  }

  public flagRemoveUser(user: User) {
    this.store.dispatch(
      userNgrxActions.flagRemoveUser.req({
        user: {
          ...user,
          flaggedForDeletion: !user.flaggedForDeletion
        }
      })
    );
  }

  public editUser(user: User) {
    this.store.dispatch(userNgrxActions.editUser.req({ user }));
  }

  public restrictUserData(user: User) {
    this.store.dispatch(userNgrxActions.restrictUserData.req({ user }));
  }

  public promoteUser(user: User) {
    this.store.dispatch(userNgrxActions.promoteUser.req({ user }));
  }

  public demoteUser(user: User) {
    this.store.dispatch(userNgrxActions.demoteUser.req({ user }));
  }

  public updateUser(user: Partial<User>) {
    this.store.dispatch(userNgrxActions.updateUser.req(user));
  }

  public get(id: string) {
    this.store.dispatch(userNgrxActions.get.req({ id }));
  }

  public getUserWithUnique(user: UniqueUser) {
    this.store.dispatch(userNgrxActions.getUserWithUnique.req(user));
  }

  public getUserWithUnique$(user: UniqueUser) {
    return this.store.select(
      createSelector(
        userNgrxSelectors.featureSelector,
        (state) => (state.uniqueUsers || {})[UserUtil.getLookupString(user)]
      )
    );
  }

  /**
   * Adds users to the merge, uses the internal
   * "selection-ngrx" reusable logic
   */
  public addUserToMerge(user: User | string) {
    this.store.dispatch(
      userNgrxActions.select({
        id: getId(user)
      })
    );
  }

  /**
   * Removes users from the merge, uses the internal
   * "selection-ngrx" reusable logic
   */
  public removeUserFromMerge(user: User | string) {
    this.store.dispatch(
      userNgrxActions.unselect({
        id: getId(user)
      })
    );
  }

  /**
   * Clears the users selected
   */
  public selectionClear() {
    this.store.dispatch(userNgrxActions.selectionClear());
  }

  /**
   * Merges the given users.
   *
   */
  public mergeUsers() {
    this.store.dispatch(userNgrxActions.mergeUsers.req());

    this.selectionClear();
  }

  /**
   * Add/Remove query params based on given user filter
   */
  public setQueryParams(filter: UserFilter) {
    // If the filter is empty, clear every query params
    if (!Object.keys(filter).length) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          firstName: null,
          lastName: null,
          username: null,
          ssn: null,
          birthDay: null,
          shortId: null,
          company: null
        },
        queryParamsHandling: 'merge'
      });

      return;
    }

    const queryParams = Object.entries(filter).reduce((acc, [key, value]) => {
      if (key === 'company') {
        return {
          ...acc,
          company: getId(value)
        };
      }

      return {
        ...acc,
        [key]: !value
          ? // Set to null if empty/undefined to remove from query param
            null
          : value
      };
    }, {});

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  /**
   * Get the search params from userFilter
   *
   * NOTE: I originally intended to get the query params from activated route
   * however it does not emit the latest changed so I'm forced to use
   * userFilter.
   *
   * See more: https://github.com/angular/angular/issues/17609
   */
  public getUserSearchParams(filter: UserFilter): AdminUserSearchForm {
    return Object.entries(filter)
      .filter(([, value]) => !!value)
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: key === 'company' ? getId(value) : value
        }),
        {} as AdminUserSearchForm
      );
  }

  /**
   * Removes the user's previous companies
   */
  public desyncUser(params: { user: string | User }) {
    this.store.dispatch(
      userNgrxActions.desyncUser.req({
        user: getId(params.user)
      })
    );
  }

  /**
   * Removes the user's previous companies
   */
  public removeUserRegistration(userRegistration: UserRegistrationId) {
    return this.userRegistrationFacade.removeRegistrationOnly(userRegistration);
  }

  /**
   * Creates a partial-user
   */
  public createPartialUser(params: { user: Partial<PartialUser> }) {
    const { user } = params;

    this.store.dispatch(
      userNgrxActions.createPartialUser.req({
        user
      })
    );
  }

  /**
   * Handler for all actions emitted from individual users
   * from the `ehs-user-table`
   */
  onActionChange(params: {
    type: UserActionType | ClientAdminUserActionType;
    user: User | UserWithRegistrations;
  }) {
    const { type, user } = params;

    switch (type) {
      case AdminUserScopes.USER_LOGIN:
        this.loginAsUser(getId(user));

        return;
      case AdminUserScopes.PASSWORD_RESET:
        this.resetPassword(user);

        return;
      case AdminUserScopes.MFA_DELETE:
        this.removeMFA(user);

        return;
      case AdminUserScopes.MFA_DISABLE:
        this.disableMFA(user);

        return;

      case 'enableMFA':
        this.enableMFA(user);

        return;
      case AdminUserScopes.UPDATE_PROFILE:
        this.editUser(user);

        return;
      case AdminUserScopes.VERIFY_EMAIL:
        this.verifyEmail(user);

        return;
      case AdminUserScopes.UNLOCK_ACCOUNT:
        this.unlockAccount(user);

        return;
      case AdminUserScopes.MOVE_USER_COMPANY:
        this.moveUserCompany(user);

        return;

      case AdminUserScopes.DESYNC_USER_DATA:
        this.desyncUser({ user });

        return;
      case AdminUserScopes.REMOVE_USER:
        this.removeUser(user);

        return;

      case AdminUserScopes.CLIENT_PROMOTION:
        this.promoteUser(user);

        return;

      case 'demoteUser':
        this.demoteUser(user);

        return;
      case 'register':
        this.router.navigate(['/ehs/admin/platform/registration/create'], {
          queryParams: { user: getId(user), company: getId(user.company) }
        });

        return;
      case ClientAdminScopes.CREATE_REGISTRATION:
        this.router.navigate(
          ['ehs/client/company', getId(user.company), 'create', 'registration'],
          {
            queryParams: { user: getId(user) }
          }
        );

        return;
      case ClientAdminScopes.REMOVE_REGISTRATION:
        if (isUserWithRegistrations(user)) {
          this.removeUserRegistration(
            getId(user.userRegistrations) as UserRegistrationId
          );
        }

        return;
      case 'restrictData':
        this.restrictUserData(user);

        return;
      // **Note** this function can handle both cases
      case 'unflagRemoval':
      case 'flagRemoval':
        this.flagRemoveUser(user);

        return;
      case 'addToMerge':
        this.addUserToMerge(user);

        return;
      case 'removeFromMerge':
        this.removeUserFromMerge(user);

        return;
      case 'submitMerge':
        this.mergeUsers();

        return;
      default:
        return;
    }
  }

  /**
   * Create walk-in registration
   */
  public createWalkin(params: {
    user: PartialUser;
    eventLocation: string;
    // Leaving as optional until I confirm it won't break anything if required
    groupType?: number;
    onsiteTypes: OnsiteRegistrationType[];
  }) {
    this.store.dispatch(userNgrxActions.createWalkin.req(params));
  }

  public createAdditionalWalkin(params: {
    user: PartialUser;
    eventLocation: string;
    // Leaving as optional until I confirm it won't break anything if required
    groupType?: number;
    onsiteTypes: OnsiteRegistrationType[];
    isAdditional: boolean;
  }) {
    this.store.dispatch(userNgrxActions.createAdditionalWalkin.req(params));
  }
}
