export class TextUtil {
  /**
   * Capitalize the first character of every word.
   */
  public static capitalizeWords(str: string) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  /**
   * Get the ordinal suffix for a number.
   */
  public static getNumberOrderSuffix(num: number) {
    if (!num || typeof num !== 'number') {
      return '';
    }

    const last2DigitsString = (num + '').slice(-2);
    const last2Digits = parseInt(last2DigitsString);
    const lastDigitString = (num + '').slice(-1);
    const lastDigit = parseInt(lastDigitString);

    // Eleventh through nineteenth are all 'th'
    if (last2Digits >= 11 && last2Digits <= 19) {
      return 'th';
    }

    // Otherwise, use the last digit
    if (lastDigit === 1) {
      return 'st';
    } else if (lastDigit === 2) {
      return 'nd';
    } else if (lastDigit === 3) {
      return 'rd';
    } else {
      return 'th';
    }
  }

  /**
   * Used to format numbers with commas for better readability
   */
  public static formatNumbers(num: number): string {
    if (typeof num !== 'number') {
      return '';
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
