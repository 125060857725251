import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getId, HealthDataSearchForm, UserHealthData } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserHealthDataHttpService {
  constructor(private http: HttpClient) {}
  baseUrl = 'api/v1/health-data';

  /**
   * Load a specific user health data records
   */
  public get(id: string): Observable<UserHealthData> {
    return this.http.get<UserHealthData>(`${this.baseUrl}/${id}`);
  }

  /**
   * Load all of current users health data
   */
  public list(): Observable<UserHealthData[]> {
    return this.http.get<UserHealthData[]>(`${this.baseUrl}`);
  }

  public listByPage(
    search: HealthDataSearchForm
  ): Observable<{ entities: UserHealthData[]; hasNext: boolean }> {
    return this.http.post<{ entities: UserHealthData[]; hasNext: boolean }>(
      `${this.baseUrl}/list-by-page`,
      search
    );
  }

  public listByHealthProgram(params: {
    healthProgramId: string;
  }): Observable<UserHealthData[]> {
    return this.http.get<UserHealthData[]>(
      `${this.baseUrl}/program/${params.healthProgramId}`
    );
  }
}
