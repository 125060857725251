import { Injectable } from '@angular/core';
import { UserTest } from '@common';
import { EntityFacade } from '@ehs-ngrx/common';
import { select, Store } from '@ngrx/store';
import { userTestNgrxActions } from './user-test-ngrx.actions';
import {
  userTestNgrxFeatureSelector,
  userTestNgrxSelectors
} from './user-test-ngrx.selectors';
import {
  ParentUserTestNgrxState,
  UserTestNgrxState
} from './user-test-ngrx.state';

@Injectable()
export class UserTestNgrxFacade extends EntityFacade<
  UserTest,
  string,
  ParentUserTestNgrxState,
  UserTestNgrxState
> {
  public byPrimaryOrderCode$ = this.store.pipe(
    select(userTestNgrxSelectors.byPrimaryOrderCodeSelector)
  );

  public byLabResultCode$ = this.store.pipe(
    select(userTestNgrxSelectors.byLabResultCodeSelector)
  );

  constructor(store: Store<ParentUserTestNgrxState>) {
    super(store, userTestNgrxFeatureSelector);
  }

  /**
   * Workaround, to "force" wait for loading before setting
   * using `set`.
   */
  public setLoading(loading: boolean) {
    this.store.dispatch(userTestNgrxActions.setLoading({ loading }));
  }

  public set(entities: UserTest[]) {
    this.store.dispatch(userTestNgrxActions.setMany({ entities }));
  }

  public list() {
    this.store.dispatch(userTestNgrxActions.list.req());
  }
}
