import {
  LabResultCode,
  PrimaryOrderCode,
  toMap,
  UserTest,
  UserTestId
} from '@common';
import { entitySelectorsFactory } from '@ehs-ngrx/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ParentUserTestNgrxState,
  UserTestNgrxState,
  USER_TEST_NGRX_STORE_KEY
} from './user-test-ngrx.state';

export const userTestNgrxFeatureSelector =
  createFeatureSelector<UserTestNgrxState>(USER_TEST_NGRX_STORE_KEY);

const baseSelectors = entitySelectorsFactory<
  UserTest,
  UserTestId,
  ParentUserTestNgrxState,
  UserTestNgrxState
>({ featureSelector: userTestNgrxFeatureSelector });

const byPrimaryOrderCodeSelector = createSelector(
  baseSelectors.entitiesArraySelector,
  (entities) =>
    toMap({
      entities,
      key: 'primaryOrderCode'
    }) as Record<PrimaryOrderCode, UserTest>
);

const byLabResultCodeSelector = createSelector(
  baseSelectors.entitiesArraySelector,
  (entities) =>
    toMap({
      entities,
      key: 'labResultCode'
    }) as Record<LabResultCode, UserTest>
);

export const userTestNgrxSelectors = {
  ...baseSelectors,
  byPrimaryOrderCodeSelector,
  byLabResultCodeSelector
};
