import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, take } from 'rxjs';

import { EhsAddWaiverDialogComponent } from './ehs-add-waiver-dialog.component';
import { EhsAddWaiverDialogData } from './ehs-add-waiver-dialog.data';
import { EhsAddWaiverDialogResponse } from './ehs-add-waiver-dialog.response';
import { EhsAddWaiverUsersSearchDialogService } from './ehs-add-waiver-dialog-users.service';

@Injectable()
export class EhsAddWaiverDialogService {
  constructor(
    private matDialog: MatDialog,
    private usersService: EhsAddWaiverUsersSearchDialogService
  ) {}

  public open(
    data: EhsAddWaiverDialogData
  ): Observable<EhsAddWaiverDialogResponse | false> {
    if (!data.eventService) {
      return of(false);
    }

    this.usersService.initUsers(data.eventService);

    return this.matDialog
      .open(EhsAddWaiverDialogComponent, { data, width: '300px' })
      .afterClosed()
      .pipe(take(1));
  }
}
