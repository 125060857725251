export enum ProfileIcons {
  ELEPHANT = 'elephant',
  SNAKE = 'snake',
  PANDA = 'panda',
  LUCHADOR = 'luchador',
  USER = 'user'
}

export enum TeamIcons {
  AQUARIUM = 'aquarium',
  CAT = 'cat',
  GIRAFFE = 'giraffe',
  VOICE = 'voice',
  APPLE = 'apple',
  CITY = 'city',
  BIKE = 'bike',
  DESERT = 'desert',
  CARROT = 'carrot',
  CORN = 'corn',
  DRUMSTICK = 'drumstick',
  LANDSCAPE = 'landscape',
  TRASH = 'trash',
  GRAPE = 'grape',
  HELICOPTER = 'helicopter',
  AIR_BALLON = 'air-ballon',
  FLOPPY = 'floppy',
  PIZZA = 'pizza',
  SCOOTER = 'scooter',
  STRAWBERRY = 'strawberry',
  TARGET = 'target',
  USB = 'usb',
  POLAROID = 'polaroid',
  TEAM = 'team'
}

export const TeamIconsSrcs: { [key in TeamIcons] } = {
  [TeamIcons.AQUARIUM]: '/assets/img/team-icons/aquarium.svg',
  [TeamIcons.CAT]: '/assets/img/team-icons/cat.svg',
  [TeamIcons.GIRAFFE]: '/assets/img/team-icons/giraffe.svg',
  [TeamIcons.VOICE]: '/assets/img/team-icons/voice.svg',
  [TeamIcons.APPLE]: '/assets/img/team-icons/apple.svg',
  [TeamIcons.CITY]: '/assets/img/team-icons/city.svg',
  [TeamIcons.BIKE]: '/assets/img/team-icons/bike.svg',
  [TeamIcons.DESERT]: '/assets/img/team-icons/desert.svg',
  [TeamIcons.CARROT]: '/assets/img/team-icons/carrot.svg',
  [TeamIcons.CORN]: '/assets/img/team-icons/corn.svg',
  [TeamIcons.DRUMSTICK]: '/assets/img/team-icons/drumstick.svg',
  [TeamIcons.LANDSCAPE]: '/assets/img/team-icons/landscape.svg',
  [TeamIcons.TRASH]: '/assets/img/team-icons/trash.svg',
  [TeamIcons.GRAPE]: '/assets/img/team-icons/grape.svg',
  [TeamIcons.HELICOPTER]: '/assets/img/team-icons/helicopter.svg',
  [TeamIcons.AIR_BALLON]: '/assets/img/team-icons/air-ballon.svg',
  [TeamIcons.FLOPPY]: '/assets/img/team-icons/floppy.svg',
  [TeamIcons.PIZZA]: '/assets/img/team-icons/pizza.svg',
  [TeamIcons.SCOOTER]: '/assets/img/team-icons/scooter.svg',
  [TeamIcons.STRAWBERRY]: '/assets/img/team-icons/strawberry.svg',
  [TeamIcons.TARGET]: '/assets/img/team-icons/target.svg',
  [TeamIcons.USB]: '/assets/img/team-icons/usb.svg',
  [TeamIcons.POLAROID]: '/assets/img/team-icons/polaroid.svg',
  [TeamIcons.TEAM]: '/assets/img/team-icons/team.svg'
};

export enum MapIcons {
  WAYPOINT = 'waypoint',
  FINISH = 'finish',
  START = 'start'
}

export type Icons = ProfileIcons | TeamIcons | MapIcons;

export enum IconType {
  PROFILE = 'profile',
  TEAM = 'team',
  MAP = 'map'
}
