import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserTestNgrxProviderDirective } from './user-test-ngrx-provider.directive';
import { UserTestNgrxEffects } from './user-test-ngrx.effects';
import { UserTestNgrxFacade } from './user-test-ngrx.facade';
import {
  userResultReducer,
  USER_TEST_NGRX_STORE_KEY
} from './user-test-ngrx.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_TEST_NGRX_STORE_KEY, userResultReducer),
    EffectsModule.forFeature([UserTestNgrxEffects])
  ],
  providers: [UserTestNgrxFacade],
  declarations: [UserTestNgrxProviderDirective],
  exports: [UserTestNgrxProviderDirective]
})
export class UserTestNgrxModule {}
