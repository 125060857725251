<div fxLayout="column"
     [fxLayoutAlign]="hasSplitData ? 'space-between' : ''"
     class="full-height"
     *ngIf="data && data.length">
  <ng-container *ngIf="showProfileIcons">
    <ng-container *ngIf="user?.profileIcon && iconSrcMap[user.profileIcon]; else showDefault">
      <img [src]="iconSrcMap[user.profileIcon]"
           class="user-image"
           alt="Profile Icon">
    </ng-container>
    <ng-template #showDefault>
      <mat-icon class="user-image">account_circle</mat-icon>
    </ng-template>
  </ng-container>
  <div fxLayout="column"
       fxLayoutGap="8px"
       fxLayoutAlign="center center">
    <ng-container *ngFor="let nestedElement of startData;">
      <ehs-side-nav-element [data]="nestedElement"
                            (action)="action.emit($event)"
                            [expandedElements]="expandedElements"
                            (expandedChange)="expandedChange.emit($event)">
      </ehs-side-nav-element>
    </ng-container>
  </div>

  <div *ngIf="endData.length"
       fxLayout="column"
       fxLayoutGap="8px"
       fxLayoutAlign="center center">
    <mat-divider style="width:100%"></mat-divider>
    <ng-container *ngFor="let nestedElement of endData; let index = index;">
      <ehs-side-nav-element [data]="nestedElement"
                            (action)="action.emit($event)"
                            [expandedElements]="expandedElements"
                            (expandedChange)="expandedChange.emit($event)">
      </ehs-side-nav-element>
    </ng-container>
  </div>
</div>
