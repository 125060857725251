import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse, GcfHealthProgramExportRequest } from '@common';
import { Observable } from 'rxjs';
import { QueryListService } from '../../util/query-list.service';

const BASE_URL = '/api/v1/health-program';
const ADMIN_URL = '/api/v1/admin/health-program';

@Injectable({
  providedIn: 'root'
})
export class AdminHealthProgramHttpService {
  constructor(
    private http: HttpClient,
    private queryList: QueryListService
  ) {}

  /**
   * Export health program for the given company/event-service
   */
  public exportHealthProgram(
    params: Omit<GcfHealthProgramExportRequest, 'email'>
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(`${ADMIN_URL}/export`, params);
  }
}
