<div>
  <!-- Is there a way to just add/remove accordion without repeating code on line 138? -->
  <mat-accordion [hideToggle]="!collapse"
                 style="margin:0;">
    <mat-expansion-panel class="mat-elevation-z0"
                         [disabled]="collapse"
                         [expanded]="clientAdmin || !collapse">
      <mat-expansion-panel-header style="margin:0px;"
                                  collapsedHeight="40px"
                                  expandedHeight="40px">
        <div fxLayout="row"
             fxLayoutAlign="space-between start"
             style="width:100%"
             *ngIf="eventLocation">
          <div fxLayout="row"
               fxLayoutAlign="start start"
               fxLayoutGap="8px">
            <div [title]="isActive ? 'Event is Active' : 'Event is Inactive'"
                 style="min-height: 10px; min-width: 10px; border-radius: 50%; top: 14px; position: relative;"
                 [ngStyle]="{background: isActive ? 'green' : 'red' }">
            </div>
            <a mat-button
               title="Redirects to Offsite Participant List"
               [routerLink]="[adminLevel, eventLocation.company, 'service', eventLocation.service, 'event', eventLocation._id]"
               color="primary"
               class="title-wrap">
              {{eventLocation.locationCompanyName}} -
              {{eventLocation.city}}, {{eventLocation.state}} -
              {{eventLocation.eventDate | date:'mediumDate' || 'Not Set'}}
              ( {{getAmPm(eventLocation.startTime)}} -
              {{getAmPm(eventLocation.endTime)}} )
            </a>
          </div>

          <div fxShow
               fxHide.lt-md
               fxLayout="row"
               fxLayoutAlign="start start"
               fxLayoutGap="8px"
               *ngIf="!clientAdmin">
            <button mat-icon-button
                    type="button"
                    style="width:40px;height:40px"
                    [matMenuTriggerFor]="optionMenu"
                    (click)="$event.stopPropagation(); $event.preventDefault()">
              <mat-icon class="vert-icon">
                more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #optionMenu="matMenu">
            <div fxLayout="column"
                 fxLayoutAlign="center center">
              <ehs-gwapps-actions entityType="eventService"
                                  [entity]="eventLocation"
                                  *ngIf="!clientAdmin"
                                  (view)="view.emit(eventLocation)"
                                  (validate)="validate.emit(eventLocation)">
              </ehs-gwapps-actions>
            </div>
            <mat-divider></mat-divider>
            <a type="button"
               mat-menu-item
               [routerLink]="companyUrl"
               *ngIf="showCompany">
              <mat-icon>link</mat-icon>
              View Company
            </a>
            <a type="button"
               mat-menu-item
               [routerLink]="serviceUrl">
              <mat-icon>link</mat-icon>
              View Service
            </a>
            <a type="button"
               mat-menu-item
               [routerLink]="registrationsUrl">
              <mat-icon>link</mat-icon>
              View Registrations
            </a>
            <button type="button"
                    mat-menu-item
                    (click)="cancelEvent()">
              <mat-icon>cancel</mat-icon>
              Cancel Event
            </button>
            <a type="button"
               mat-menu-item
               [routerLink]="editRateUrl">
              <ng-container *ngIf="canEditRate else showViewTimes">
                <mat-icon>edit</mat-icon>
                Edit Rate
              </ng-container>
              <ng-template #showViewTimes>
                <mat-icon>view_week</mat-icon>
                View Times
              </ng-template>
            </a>
            <button type="button"
                    mat-menu-item
                    (click)="exportRegistrations()">
              <mat-icon>add_to_drive</mat-icon>
              Export Registrations
            </button>
            <button type="button"
                    mat-menu-item
                    (click)="exportFieldStaff()">
              <mat-icon>add_to_drive</mat-icon>
              Export Field Staff
            </button>
            <button type="button"
                    mat-menu-item
                    (click)="sendNotification()">
              <mat-icon>send</mat-icon>
              Compose Notification
            </button>
          </mat-menu>

        </div>
      </mat-expansion-panel-header>

      <div gdColumns="1fr 1fr"
           gdGap="8px"
           style="position: relative; top: -8px">

        <ehs-card-label *ngIf="eventLocation.locationCompanyName && !clientAdmin"
                        label="Registration Dates"
                        [value]="(eventLocation.registrationActive | date: 'mediumDate') + ' - ' + (eventLocation.registrationDeadline | date: 'mediumDate')"
                        [valueStyles]="{fontSize: '12px', background: '#fff59d', padding: '2px 6px', borderRadius: '5px', display: 'inline'}">
        </ehs-card-label>

        <ehs-card-label label="Event ID"
                        *ngIf="!clientAdmin"
                        [value]="eventLocation.eventId"></ehs-card-label>
      </div>
      <div gdColumns="1fr 1fr 1fr"
           gdGap="8px"
           style="position: relative; ">
        <div>
          <mat-label style="font-weight: bold;">Registration Screenings</mat-label>
          <ehs-card-label label="Registered"
                          [value]="fullEventStats?.registrationsCount || 0">
          </ehs-card-label>

          <ehs-card-label label="Remaining"
                          [value]="fullEventStats?.remainingScreenings || 0">
          </ehs-card-label>
        </div>
        <div>
          <mat-label style="font-weight: bold;">Registration Vaccinations</mat-label>
          <ehs-card-label label="Registered"
                          [value]="fullEventStats?.vaccinationRegistrationsCount || 0">
          </ehs-card-label>

          <ehs-card-label label="Remaining"
                          [value]="fullEventStats?.remainingVaccinations || 0">
          </ehs-card-label>
        </div>
        <div>
          <mat-label style="font-weight: bold;">Results</mat-label>
          <ehs-card-label label="Screening"
                          [value]="fullEventStats?.resultedScreenings || 0">
          </ehs-card-label>

          <ehs-card-label label="Vaccination"
                          [value]="fullEventStats?.resultedVaccinations || 0">
          </ehs-card-label>
        </div>
      </div>

      <div gdColumns="1fr 1fr"
           *ngIf="!hideAddress"
           style="margin-top: 8px;">
        <ehs-card-label label="Address"
                        [value]="eventAddress"></ehs-card-label>

        <ehs-card-label *ngIf="eventLocation.room"
                        label="Room"
                        [value]="eventLocation.room"></ehs-card-label>

        <ehs-card-label *ngIf="eventLocation.locationCompanyName"
                        label="Company"
                        [value]="eventLocation.locationCompanyName">
        </ehs-card-label>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>