import { UserTest } from '@common';
import { selectId } from '@ehs-ngrx/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { userTestNgrxActions } from './user-test-ngrx.actions';

export const USER_TEST_NGRX_STORE_KEY = 'userTestNgrx';

export interface ParentUserTestNgrxState {
  [USER_TEST_NGRX_STORE_KEY]: UserTestNgrxState;
}

export interface UserTestNgrxState extends EntityState<UserTest> {
  loading?: boolean;
}

const adapter = createEntityAdapter({ selectId });

export const userResultReducer = createReducer(
  adapter.getInitialState({}),
  on(userTestNgrxActions.setLoading, (state, { loading }) => ({
    ...state,
    loading
  })),
  on(userTestNgrxActions.setMany, (state, { entities }) =>
    adapter.upsertMany(entities, state)
  ),
  on(userTestNgrxActions.list.req, (state) => ({ ...state, loading: true })),
  on(userTestNgrxActions.list.success, (state, { payload: { entities } }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(userTestNgrxActions.list.failed, (state) => ({ ...state, loading: false }))
);
