import { Injectable } from '@angular/core';
import { HealthDataSearchForm, UserHealthData } from '@common';
import { createSelector, select, Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { Actions } from '@ngrx/effects';
import { userHealthDataActions } from './user-health-data.actions';
import { userHealthDataSelectors } from './user-health-data.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserHealthDataFacade {
  public loading$ = this.store.pipe(
    select(userHealthDataSelectors.loadingSelector)
  );

  public hasNext$ = this.store.select(userHealthDataSelectors.hasNextSelector);

  public pageNumber$ = this.store.select(
    userHealthDataSelectors.pageNumberSelector
  );

  public ids$ = this.store.pipe(select(userHealthDataSelectors.idsSelector));
  public entities$ = this.store.pipe(
    select(userHealthDataSelectors.entitiesSelector)
  );

  public list$ = this.store.pipe(
    select(
      createSelector(
        (state: AppState) => state.userHealthData.entities,
        (entities) => Object.values(entities || {}) as Array<UserHealthData>
      )
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  public get$(id: string) {
    return this.store.pipe(
      select((state: AppState) => state.userHealthData.entities[id])
    );
  }

  public get(params: { id: string }) {
    const { id } = params;

    this.store.dispatch(userHealthDataActions.get({ id }));
  }

  public list() {
    this.store.dispatch(userHealthDataActions.list());
  }

  public listByPage(params: HealthDataSearchForm) {
    this.store.dispatch(userHealthDataActions.listByPage({ search: params }));
  }

  /**
   * Returns the health data record with the earliest date currently loaded
   */
  public getEarliestDate$() {
    return this.store.pipe(
      select(userHealthDataSelectors.earliestDateSelector)
    );
  }

  public loadMore(params: HealthDataSearchForm) {
    this.store.dispatch(userHealthDataActions.loadMore({ search: params }));
  }

  public listByHealthProgram(params: { healthProgramId: string }) {
    const { healthProgramId } = params;

    this.store.dispatch(
      userHealthDataActions.listByHealthProgram({ healthProgramId })
    );
  }
}
