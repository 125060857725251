import { ProgramStatus } from '../constants/health-program-values';
import { HealthProgram } from '../models/health-program/health-program';

export class HealthProgramUtil {
  /**
   * Return the health program with the earliest start date.
   */
  public static getEarliestHealthProgram(
    healthPrograms: HealthProgram[]
  ): HealthProgram {
    return (healthPrograms || []).reduce((earliest, healthProgram) => {
      if (!earliest || healthProgram.startDate < earliest.startDate) {
        return healthProgram;
      }

      return earliest;
    }, null);
  }

  public static getProgramStatus(loadedProgram: HealthProgram) {
    if (!loadedProgram) {
      return '';
    }

    const today = new Date();
    const registrationStartDate = new Date(loadedProgram.registrationStartDate);
    const startDate = new Date(loadedProgram.startDate);
    const endDate = new Date(loadedProgram.endDate);
    const lastSyncDate = new Date(loadedProgram.lastSyncDate);

    if (!registrationStartDate || !startDate || !endDate || !lastSyncDate) {
      return '';
    }

    if (registrationStartDate > today) {
      return ProgramStatus.COMING_SOON;
    }

    if (today >= registrationStartDate && today < startDate) {
      return ProgramStatus.REGISTRATION_OPEN;
    }

    if (today >= startDate && today < endDate) {
      return ProgramStatus.ACTIVE;
    }

    if (today >= endDate && today < lastSyncDate) {
      return ProgramStatus.ENDING_SOON;
    }

    return ProgramStatus.ENDED;
  }
}
