import { UserHealthData } from '@common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import { userHealthDataActions } from './user-health-data.actions';

export interface UserHealthDataState extends EntityState<UserHealthData> {
  loading?: boolean;
  hasNext?: boolean;
  pageNumber: number;
}

const adapter = createEntityAdapter<UserHealthData>({
  selectId
});

const reducer = createReducer<UserHealthDataState>(
  adapter.getInitialState({ pageNumber: 1 }),
  on(userHealthDataActions.get, (state) => ({
    ...state,
    loading: true
  })),
  on(userHealthDataActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userHealthDataActions.getFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(userHealthDataActions.loadMore, (state) => ({
    ...state,
    loading: true,
    pageNumber: state.pageNumber + 1
  })),

  on(userHealthDataActions.listByPage, (state) => ({
    ...state,
    loading: true
  })),
  on(userHealthDataActions.listByPageSuccess, (state, { entities, hasNext }) =>
    adapter.upsertMany(entities, { ...state, loading: false, hasNext })
  ),
  on(userHealthDataActions.listByPageFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(userHealthDataActions.list, (state) => ({
    ...state,
    loading: true
  })),

  on(userHealthDataActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, {
      ...state,
      loading: false
    })
  ),

  on(userHealthDataActions.listFailed, (state: UserHealthDataState) => ({
    ...state,
    loading: false
  })),

  on(userHealthDataActions.listByHealthProgram, (state) => ({
    ...state,
    loading: true
  })),
  on(userHealthDataActions.listByHealthProgramSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(userHealthDataActions.listByHealthProgramFailed, (state) => ({
    ...state,
    loading: false
  }))
);

export function UserHealthDataReducer(
  state: UserHealthDataState,
  action: Action
) {
  return reducer(state, action);
}
