import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HealthProgramTeam, TeamMemberDetails } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HealthProgramTeamHttpService {
  constructor(private http: HttpClient) {}

  public list(): Observable<HealthProgramTeam[]> {
    return this.http.get<HealthProgramTeam[]>('/api/v1/health-program-team');
  }

  public listTeamsByProgram(
    healthProgramIds: string[]
  ): Observable<HealthProgramTeam[]> {
    return this.http.post<HealthProgramTeam[]>(
      `/api/v1/health-program-team/program`,
      healthProgramIds
    );
  }

  public get(id: string): Observable<HealthProgramTeam> {
    return this.http.get<HealthProgramTeam>(
      `/api/v1/health-program-team/${id}`
    );
  }

  public update(params: {
    entity: HealthProgramTeam;
    joining?: string;
  }): Observable<HealthProgramTeam> {
    return this.http.put<HealthProgramTeam>(
      `/api/v1/health-program-team/update`,
      { entity: params.entity, joining: params.joining }
    );
  }

  public create(params: {
    entity: Partial<HealthProgramTeam>;
    createdBy?: string;
  }): Observable<HealthProgramTeam> {
    return this.http.put<HealthProgramTeam>(
      `/api/v1/health-program-team/create`,
      {
        entity: params.entity,
        createdBy: params.createdBy
      }
    );
  }

  /**
   * Get user details for each team in the given health program
   */
  public getUserDetailsByTeam(
    healthProgramIds: string[]
  ): Observable<Record<string, TeamMemberDetails[]>> {
    return this.http.post<Record<string, TeamMemberDetails[]>>(
      `/api/v1/health-program-team/program/users`,
      healthProgramIds
    );
  }
}
